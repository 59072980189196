export const data = [
  {
    to: "/",
    text: "Home",
    id: "home",
  },
  {
    to: "/pricing",
    text: "Pricing",
  },
  {
    to: "/signup",
    text: "Sign up",
  },
];
